// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'
import './stylesheets/login.scss'
import './stylesheets/main.scss'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('turbolinks:load', function (e) {
    var el = document.getElementById('generation_type_custom')
    if (el) {
        el.addEventListener("click", (e) => {
            document.getElementById('mix_block').classList.remove('d-none')
            document.getElementById('number_of_questions_block').classList.add('d-none')
            document.getElementById('manual_selection').classList.add('d-none')
        })
    }
    var el = document.getElementById('generation_type_random')
    if (el) {
        el.addEventListener("click", (e) => {
            document.getElementById('mix_block').classList.add('d-none')
            document.getElementById('number_of_questions_block').classList.remove('d-none')
            document.getElementById('manual_selection').classList.add('d-none')
        })
    }
    var el = document.getElementById('generation_type_manual')
    if (el) {
        el.addEventListener("click", (e) => {
            document.getElementById('mix_block').classList.add('d-none')
            document.getElementById('number_of_questions_block').classList.add('d-none')
            document.getElementById('manual_selection').classList.remove('d-none')
        })
    }
});